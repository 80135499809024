import React from 'react';
import styled from 'styled-components';

const Confirm = props => {
  return (
    <>
      <FormContainer>
        <form>
          <TextContainer>
            <div>
              <p htmlFor="name">お名前</p> <p>{props.data.name}</p>
            </div>
            <div>
              <p htmlFor="email">Eメール</p>
              <p>{props.data.email}</p>
            </div>
            <div>
              <p htmlFor="subject">件名</p>
              <p>{props.data.subject}</p>
            </div>

            <div>
              <p htmlFor="text">お問い合わせ内容</p>
              <p>{props.data.text}</p>
            </div>
          </TextContainer>

          <SubmitButton
            type="submit"
            onClick={() => props.changeFinishScreens()}
          >
            送信する
          </SubmitButton>

          <DoCorrectionButton
            type="submit"
            onClick={() => props.doCorrection()}
          >
            修正する
          </DoCorrectionButton>
        </form>
      </FormContainer>
    </>
  );
};

export default Confirm;

const SubmitButton = styled.button`
  background-color: #1e73be;
  padding: 15px 25px;
  border-radius: 3px;
  height: auto;
  transition: all 0.3s;
  cursor: pointer;
  color: #fff;
  margin-top: 24px;
  width: 100%;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: #f6f6f6;
    cursor: not-allowed;
  }
`;

const DoCorrectionButton = styled.button`
  background-color: #687988;
  padding: 15px 25px;
  border-radius: 3px;
  height: auto;
  transition: all 0.3s;
  cursor: pointer;
  color: #fff;
  margin-top: 24px;
  width: 100%;

  &:hover {
    opacity: 0.6;
  }
`;

const TextContainer = styled.div`
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 18px;

  & > div {
    display: flex;
    border-top: 2px solid #dfd8d8;
    box-sizing: border-box;
    padding: 16px 12px;

    // 768px以上で適応されるスタイリング
    @media (min-width: 768px) {
      padding: 16px 48px;
    }

    &:last-child {
      border-bottom: 2px solid #dfd8d8;
    }

    & > p {
      margin-bottom: 0px;
      &:first-child {
        margin: 0 16px 0 0;
        min-width: 130px;
        // 768px以上で適応されるスタイリング
        @media (min-width: 768px) {
          min-width: 150px;
        }
      }
    }
  }
`;
const FormContainer = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  input {
    border: 2px solid #dfd8d8;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 18px;
    &:focus {
      border: 2px solid #1e73be;
      outline: none;
    }
  }

  textarea {
    height: 150px;
    border: 2px solid #dfd8d8;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    &:focus {
      border: 2px solid #1e73be;
      outline: none;
    }
  }

  .c-error {
    background-color: #f7dcd9;
    border-color: #ff6366;
  }

  @media (min-width: 768px) {
    width: 640px;
    margin: 0 auto;
  }
`;
