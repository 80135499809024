import React, { useState, useEffect } from 'react';
// import { useForm } from 'react-hook-form';
import { Link } from 'gatsby';
import axios from 'axios';
import styled from 'styled-components';
import Confirm from './confirm';
import Loading from '../loading';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.interceptors.response.use(
  // 成功時のレスポンスはそのまま使う
  response => response,
  // エラーにレスポンスオブジェクトが格納されていたら返す
  error => error.response || error
);

const ContactForm = () => {
  // WordPress環境のURL
  const WEBSITE_URL = `https://blog-contact-api.hn-pgtech.com`;
  // Contact Form 7 で作ったフォームの Post ID
  const FORM_ID = `${process.env.FORM_ID}`;
  const [token, setToken] = useState('');

  const [value, setValue] = useState({});
  const [serverResponse, setServerResponse] = useState(``);
  const [isSubmit, setIsSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * フォームでsubmitが発生した時の処理
   */
  const changeFinishScreens = async () => {
    setIsStep({
      contact: false,
      confirm: false,
      finish: true,
    });

    const formData = new FormData();
    formData.set('your-name', value['name']);
    formData.set('your-email', value['email']);
    formData.set('your-subject', value['subject']);
    formData.set('your-message', value['text']);
    formData.set('_wpcf7_unit_tag', process.env.WPCF7_UNIT_TAG);

    const axiosConfig = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };

    const response = await axios.post(`${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`, formData, axiosConfig);

    setServerResponse(response);
    // 送信処理が終了したらローディング画面を消す
    setIsLoading(false);
  };

  /**
   * コンポーネントがマウントされたときの処理
   * JWT トークンを取得してステートに格納する
   */
  useEffect(() => {
    (async () => {
      const URL = `${WEBSITE_URL}/wp-json/jwt-auth/v1/token`;
      const USER_DATA = {
        username: `${process.env.JWT_AUTH_USER}`,
        password: `${process.env.JWT_AUTH_PASS}`,
        wpcf7_unit_tag: `5`
      };

      const response = await axios.post(URL, USER_DATA);

      if (response.status === 200) {
        setToken(response.data.token);
      }

      if (response.status === 403) {
        console.log('認証に失敗しました。トークンを取得できませんでした。');
      }
    })();
  }, [WEBSITE_URL]);

  // フォームの表示を切り替えるステート
  const [isStep, setIsStep] = useState({
    contact: true,
    confirm: false,
    finish: false,
  });

  // フォームが入力されるたびに実行される処理
  const handleChange = e => {
    value[e.target.id] = e.target.value;
    setServerResponse(``);
    setValue({ ...value });
    handleIsSubmit();
  };

  // ボタンの活性化・非活性化を制御するメソッド
  const handleIsSubmit = () => {
    // 値が入力されるまでは value = {} のプロパティは undefined なので以降の処理を実行しない
    // 文字列が入力された後は、各プロパティに空文字が格納されているのでこの条件は適応されない
    if (value.name === undefined || value.email === undefined || value.subject === undefined || value.text === undefined) {
      return;
    }
    // どれか一つのフォームが未入力だった場合は、送信ボタンを非活性化の状態にする
    // INFO: 空文字の判定はJavaScriptの場合は string.length で行う
    let isState = value.name.length === 0 || value.email.length === 0 || value.subject.length === 0 || value.text.length === 0 ? true : false;
    setIsSubmit(isState);
  };

  // 確認画面へ進む処理
  const nextStep = e => {
    e.preventDefault();

    setIsStep({
      contact: false,
      confirm: true,
      finish: false,
    });
  };

  // 確認画面で戻るボタンを実行した際にステートを変更する
  const doCorrection = () => {
    setIsStep({
      contact: true,
      confirm: false,
      finish: false,
    });
  };

  if (isStep.contact) {
    return (
      <>
        <FormContainer>
          <FormContentsContact>
            <p>間違いのご指摘、その他サイト管理者への連絡など、このフォームを利用してお問い合わせください。</p>
          </FormContentsContact>
          <form onSubmit={nextStep}>
            <label htmlFor="name">
              お名前 <RequireSpan>必須</RequireSpan>
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={value[`name`] || ``}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">
              Eメール <RequireSpan>必須</RequireSpan>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={value[`email`] || ``}
              onChange={handleChange}
              required
            />

            <label htmlFor="subject">
              件名 <RequireSpan>必須</RequireSpan>
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              value={value[`subject`] || ``}
              onChange={handleChange}
              required
            />

            <label htmlFor="text">
              お問い合わせ内容 <RequireSpan>必須</RequireSpan>
            </label>
            <textarea
              name="text"
              id="text"
              onChange={handleChange}
              defaultValue={value[`text`] || ``}
              required
            ></textarea>

            <SubmitButton
              type="submit"
              disabled={isSubmit}
            >
              確認する
            </SubmitButton>
          </form>
        </FormContainer>
      </>
    );
  } else if (isStep.confirm) {
    return (
      <>
        <FormContainer>
          <FormContents>
            <p>こちらの内容を送信します。よろしいですか？</p>
          </FormContents>
          <Confirm
            data={value}
            doCorrection={doCorrection}
            changeFinishScreens={changeFinishScreens}
          />
        </FormContainer>
      </>
    );
  } else if (isStep.finish) {
    if (isLoading) {
      return (
        <>
          <FormContainer>
            <Loading />
            <div>
              <Link
                className="c-button c-button__top"
                to="/"
                type="submit"
              >
                トップ画面に戻る
              </Link>
            </div>
          </FormContainer>
        </>
      );
    }

    const finish =
      serverResponse.status === 200 ? (
        <>
          <p>お問い合わせ内容を送信しました。</p>
        </>
      ) : (
        <>
          <p>
            メールの送信に失敗しました。
            <br />
            しばらく経過してから再度送信して下さい。
          </p>
          <p style={{ marginBottom: '0px' }}>お急ぎの場合はTwitterのDMより連絡をお願い致します。</p>
          <p>
            <Link
              style={{ fontSize: '3rem' }}
              href="https://twitter.com/messages/compose?recipient_id=1112482133199589376"
              target="\_brank"
              rel="noopener"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="twitter"
                class="svg-inline--fa fa-twitter bio-twitter"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"
                ></path>
              </svg>
            </Link>
          </p>
        </>
      );

    return (
      <>
        <FormContainer>
          <FormContents>{finish}</FormContents>
          <div>
            <Link
              className="c-button c-button__top"
              to="/"
              type="submit"
            >
              トップ画面に戻る
            </Link>
          </div>
        </FormContainer>
      </>
    );
  }
};

export default ContactForm;

const RequireSpan = styled.span`
  margin-left: 4px;
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1.1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: #d9534f;
`;

const FormContentsContact = styled.div`
  padding-top: 40px;
  padding-bottom: 24px;
  text-align: center;
`;

const FormContents = styled.div`
  padding-top: 40px;
  padding-bottom: 24px;
  text-align: center;
  font-size: 24px;
  white-space: pre-wrap;
`;

const FormContainer = styled.div`
  width: 100%;

  .c-button {
    display: inline-block;
    text-align: center;
    padding: 15px 25px;
    border-radius: 3px;
    height: auto;
    transition: all 0.3s;
    cursor: pointer;
    color: #fff;
    width: 100%;

    &:hover {
      opacity: 0.6;
    }

    &__top {
      background-color: #1e73be;
      margin-top: 24px;
    }
  }

  label {
    margin-bottom: 2px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  input {
    border: 2px solid #dfd8d8;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 18px;
    &:focus {
      border: 2px solid #1e73be;
      outline: none;
    }
  }

  textarea {
    height: 150px;
    border: 2px solid #dfd8d8;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    &:focus {
      border: 2px solid #1e73be;
      outline: none;
    }
  }

  .c-error {
    background-color: #f7dcd9;
    border-color: #ff6366;
  }

  @media (min-width: 768px) {
    width: 640px;
    margin: 0 auto;
  }
`;

const SubmitButton = styled.button`
  background-color: #1e73be;
  padding: 15px 25px;
  border-radius: 3px;
  height: auto;
  transition: all 0.3s;
  cursor: pointer;
  color: #fff;
  margin-top: 24px;
  width: 100%;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: #f6f6f6;
    cursor: not-allowed;
  }
`;
