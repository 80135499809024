import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import styled from 'styled-components';

// Markdownファイルから変換されたHTMLに手を加える
import rehypeReact from 'rehype-react';

// お問い合わせページ用のフォームコンポーネント
import ContactForm from '../components/contactform/contact';

// リンクカードコンポーネント
import LinkCard from '../components/link-card';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    // Markdownファイル内で card というタグをリンクカードに変換する
    card: LinkCard,
    contact: ContactForm,
  },
}).Compiler;

const PagePost = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  if (location.pathname === '/contact/') {
    // コンタクトページだった場合
    return (
      <Layout
        location={location}
        title={siteTitle}
      >
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          location={location}
        />
        <Article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <ContactPageHeader>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
          </ContactPageHeader>
          <SectionWrapper itemProp="articleBody">{renderAst(post.htmlAst)}</SectionWrapper>
          {/* <SectionWrapper
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          /> */}
        </Article>
      </Layout>
    );
  } else {
    // その他のページだった場合（プライバシーポリシーなど）
    return (
      <Layout
        location={location}
        title={siteTitle}
      >
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          location={location}
        />
        <Article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
          </header>
          <SectionWrapper itemProp="articleBody">{renderAst(post.htmlAst)}</SectionWrapper>
          {/* <SectionWrapper
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          /> */}
        </Article>
      </Layout>
    );
  }
};

export default PagePost;

export const pageQuery = graphql`
  query PagePostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      # htmlからhtmlAstに書き換える
      htmlAst
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        cate
        tags
      }
    }
  }
`;
const ContactPageHeader = styled.header`
  text-align: center;
`;

const Article = styled.article`
  max-width: 750px;
  margin: 0px auto;
  padding-top: 32px;

  @media (max-width: 768px) {
    padding-bottom: 24px;
  }

  @media (min-width: 769px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  h1 {
    margin-bottom: 24px;
  }
`;
const SectionWrapper = styled.section`
  blockquote {
    padding: 2.3em 7%;
    margin: 2em 0 2.5em;
    position: relative;
    border: 2px solid rgba(103, 103, 103, 0.4);
    border-radius: 7px;
    opacity: 0.9;

    &::before {
      font-family: serif;
      position: absolute;
      width: 1em;
      height: 1em;
      line-height: 1.5;
      display: block;
      padding: 0;
      text-align: center;
      font-size: 420%;
      opacity: 0.5;
    }
    &::after {
      font-family: serif;
      position: absolute;
      width: 1em;
      height: 1em;
      line-height: 1.5;
      display: block;
      padding: 0;
      text-align: center;
      font-size: 420%;
      opacity: 0.5;
    }

    & > p {
      margin-bottom: 0;
    }
  }

  blockquote > :last-child {
    margin-bottom: var(--spacing-0);
  }

  blockquote > ul,
  blockquote > ol {
    list-style-position: inside;
  }

  h1 {
    border-left: 10px solid #ff6768;
    border-bottom: 1px #999 dotted;
    margin-bottom: 24px;
    font-size: 32px;
    padding: 10px 20px 10px 20px;
  }

  h2 {
    position: relative;
    border: none;
    font-size: 1.25em;
    padding: 0.9em 1em;
    margin-top: 2.1em;
    margin-bottom: 2em;
    background: #ff6768;
    color: #ffffff;
    -webkit-box-shadow: 0 1px 5px rgb(0 0 0 / 3%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 3%);

    &:after {
      content: '';
      position: absolute;
      border-top: 13px solid #ff6768;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
      bottom: -11px;
      left: 5%;
    }
  }

  h3 {
    padding: 8px 8px 8px 12px;
    font-size: 1.6rem;
    border-left: solid 6px #ff6768;
    margin-bottom: 1.3rem;
  }

  .gatsby-code-title {
    background-color: rgb(116, 109, 101);
    color: white;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
    display: table;
    border-radius: 4px 4px 0 0;
  }

  .gatsby-code-title span {
    display: inline;
    position: relative;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    color: #eee;
    border-top-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    padding: 3px;
    top: 1px;
  }

  pre[class*='language-'] {
    padding: 1em;
    margin: 0px;
    overflow: auto;
    border-radius: 0;
  }

  .iframely-embed {
    margin-bottom: 24px;
    transition: all 0.3s ease 0s;

    &:hover {
      box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
      transform: translateY(-4px);
    }
  }

  p {
    margin-bottom: 24px;

    & > a {
      word-break: break-all;
    }
  }

  ul {
    padding-left: 8px;
    list-style: none;

    & li {
      list-style: none;
      position: relative;
      padding-left: 18px;
      margin: 16px 0;

      &::before {
        display: block;
        position: absolute;
        left: 2px;
        top: 8px;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #000;
      }
    }
  }

  // テーブルのレイアウト
  table {
    border-collapse: collapse;
    margin-bottom: 24px;
  }

  table tr {
    background: #ffffff;
  }

  table th {
    font-weight: 700;
    background-color: #f2f2f2;
  }

  table th,
  table td {
    color: #000;
    border: solid 1px #ddd;
    padding: 10px;
  }
`;
